
import { S3 } from "@aws-sdk/client-s3";

const s3Client = new S3({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: "https://sabrecwa-storage-v1.fra1.digitaloceanspaces.com ",
    region: process.env.REACT_APP_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    }
});

export { s3Client };