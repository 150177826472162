const JoinusJson = [
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/hp-tech-trans-small-min-1440x862.jpg",
        date : 'July 18, 2022',
        title : "Inclusion & Diversity",
        btnlabel : "I&D AT SABRE",
        subtl : "We embrace our broad, rich, and unique differences while being committed to providing an inclusive environment where inclusion and diversity are built into our culture worldwide."
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/sabre-travel-ai.jpg",
        date : 'August 8, 2022',
        title : "BENEFITS & WELLNESS",
        btnlabel : "GO DEEPER",
        subtl : "We want you to be your best—therefore, your well-being is our top priority: financially, mentally and physically. Learn what types of programs are available to you once you join Sabre."
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/tech-tile-ndc-min.jpg",
        date : 'August 18, 2022',
        title : "Professional Interests & Development",
        btnlabel : "LEARN MORE",
        subtl : "We love it when team members strive for personal excellence by advancing their skills and expanding their knowledge. Learn about the resources available so you can enrich your career and professional journey."
    }
]

export default JoinusJson