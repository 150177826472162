import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Landing from './Pages/Landingpage';
import Navigationbar from './Components/Navbarr';
import Footers from './Components/Footer/Footer';
import Blogpage from './Pages/Blogpage/Blogpage';
import Executiveteam from './Pages/Executiveteam/Executiveteam';
import Babcock from './Pages/Babcock/Babcock';
import Resources from './Pages/Resource/resources';
import Contactus from './Pages/Contactus/contactus';
import Aboutus from './Pages/Aboutus/Aboutus';
import Joinusnow from './Pages/Joinus/Joinusnow';
import Babcockpage from './Pages/Babcock/Babcock';
import Videos from './Pages/Resource/videos';


const App = () => {
  return (
    <Router>
      <Navigationbar />
      <div className="AppContainer">
          <Routes>
            <Route exact path="/" element={<Landing/>} /> 
            <Route exact path="/aboutus" element={<Aboutus/>} />
            <Route exact path="/contact-us" element={<Contactus/>} />
            <Route exact path="/join-us" element={<Joinusnow/>} />
            {/* <Route exact path="/join-us-babcock" element={<Babcockpage/>} /> */}
            <Route exact path="/our-blog" element={<Blogpage />}/>
            {/* <Route exact path="/response/executiveteam" element={<Executiveteam />}/> */}
            <Route exact path="/join-us-babcock" element={<Babcockpage />}/>
            <Route exact path="/response/resource" element={<Resources />}/>
            <Route exact path="/response/videos" element={<Videos />}/>

          </Routes>
      </div>
      <Footers />
    </Router>
  );
}

export default App;