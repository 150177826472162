const InnovationJson = [
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/hp-tech-trans-small-min-1440x862.jpg",
        date : 'July 18, 2022',
        title : "Digital Transformation",
        btnlabel : "WHAT'S THE BENEFIT?",
        urlink: "https://www.sabre.com/insights/tech-transformation/",
        subtl : "To help advance the travel industry with next-gen solutions, Sabre is building a cloud-based technology environment to unlock greater efficiencies and new capabilities."
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/sabre-travel-ai.jpg",
        date : 'August 8, 2022',
        title : "Sabre Travel AI",
        btnlabel : "GO DEEPER",
        urlink: "https://www.sabre.com/page/sabre-travel-ai",
        subtl : "Our AI-driven technology that learns continuously from consumer behavior unlocks more revenue opportunities by helping travel businesses redefine their retailing and customer strategies."
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/tech-tile-ndc-min.jpg",
        date : 'August 18, 2022',
        title : "Explore Beyond NDC",
        btnlabel : "EXPLORE MORE",
        urlink: "https://www.sabre.com/insights/ndc/",
        subtl : "We empower our partners with a powerful system, unmatched industry experience and an open platform that enables innovation and customization."
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/hp-travel-ai-2-min.jpg",
        date : 'August 28, 2022',
        title : "Sabre Retail Intelligence",
        btnlabel : "LEARN MORE",
        urlink: "https://www.sabre.com/retail-intelligence/",
        subtl : "Sabre Retail Intelligence personalizes, packages and prices offers with advanced machine-learning capabilities. This innovative product suite enables embedded intelligence, continuous learning and scale at speed."
    },
]

export default InnovationJson