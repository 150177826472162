import React, {useState} from 'react'
import { IoChevronDown } from "react-icons/io5";
import './Singleselect.css'

let datearr = ['January', '1st']

const Dateselector = ({selectedSocVal, check, itemListday, itemListmon, placeholder}) => {
    const [socialvalue, setSocialvalue] = useState("")
    const [social, setSocial] = useState(false)
    const [daysocial, setDaysocial] = useState(false)

    const [socialjs] = useState(itemListmon)
    const [daysocjs] = useState(itemListday)
    const [dateval, setDateval] = useState({
        day: '',
        month: ''
    })

    // const [datearr, setDatearr] = useState([])

    let newvalue = ''
    let newarr

    const handleSocialFocus = () =>{
        setSocial(!social)
        setDaysocial(false)
    }

    const handledaySocialFocus = () =>{
        setDaysocial(!daysocial)
        setSocial(false)
    }
    
    const handleMonthClick = (e, incoming) =>{
        const daynumb = parseInt(incoming.day)
        if (incoming.index === 0){
            datearr[0] = incoming.month
            setDateval({...dateval, month: incoming.month})
        } else if (incoming.index === 1){
            datearr[1] = daynumb > 3 && daynumb < 21 ? (incoming.day + 'th') : (daynumb/4 >= 5 && daynumb%10 > 3) ? (incoming.day + 'th') : (daynumb/4 >= 5 && daynumb%10 === 3) ? (incoming.day + 'rd') : (daynumb/4 >= 5 && daynumb%10 === 2) ? (incoming.day + 'nd') : (daynumb/4 >= 5 && daynumb%10 === 1) ? (incoming.day + 'st') : (daynumb/10 === 1 && daynumb%10 < 1) ? (incoming.day + 'st') : (daynumb/10 < 1 && daynumb%10 === 2) ? (incoming.day + 'nd') : (daynumb/10 < 1 && daynumb%10 === 3) ? (incoming.day + 'rd') : (incoming.day + 'th')
            setDateval({...dateval, day: incoming.day})
        }

        newvalue = datearr[1] +' '+  datearr[0]
        setDaysocial(false)
        setSocial(false)
        selectedSocVal({sdate:newvalue, index: check})
    }

    // const handleDayClick = (e, incoming) =>{
    //     setSocialvalue(incoming.day)
    //     setSocial(!social)
    //     selectedSocVal({mon: incoming.day, day: incoming.day, index:check})
    // }
    
  return (
    <>
        <div className='single-select-container'>
            <input value={dateval.month} onFocus={handleSocialFocus} placeholder='Month' id="bnw-firname-box"/>
                <IoChevronDown id="chevron-down" onClick={handleSocialFocus}/>
                {social && <div className='bnw-region-drpdwn'>{socialjs.map((item, key) => (
                    <span onClick={e => handleMonthClick(e, {month: item.value, index: 0})} key={key}>{item.value}</span>
                ))}
                </div>}
        </div>
        
        <div className='single-select-container'>
            <input value={dateval.day} onFocus={handledaySocialFocus} placeholder='Day' id="bnw-firname-box"/>
                <IoChevronDown id="chevron-down" onClick={handledaySocialFocus}/>
                {daysocial && <div className='bnw-region-drpdwn'>{daysocjs.map((item, key) => (
                    <span onClick={e => handleMonthClick(e, {day: item.value, index: 1})} key={key}>{item.value}</span>
                ))}
                </div>}
        </div>
    </>
  )
}

export default Dateselector