const BlogFile = [
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/Sabre_Labs_Insights_Blog_Image-1-720x415.jpg",
        date : 'July 18, 2022',
        title : "Delivering software faster: How to coax Git into working great with a monorepo",
        urlink: "https://www.sabre.com/insights/5-reasons-why-lccs-are-best-placed-for-advanced-retailing/",
        subtl : "Part 2 of 5 By: Dan Cohn, Sabre Labs The first post in this series relays how we at Sabre began a multi-year effort to increase the efficiency and morale of software developers across the company…."
    },
    {
        url : "/assets/b1.jpg",
        date : 'August 8, 2022',
        title : "Video: NDC roadmap highlights with Kathy Morgan",
        urlink: "https://www.sabre.com/insights/why-is-ndc-still-a-hot-topic/",
        subtl : "2022 is a very exciting year for Beyond NDC! We think about progress in this area across three key dimensions. One is around airline integrations the second is around market expansion and the third is aroun"
    },
    {
        url : "https://www.sabre.com/wp/wp-content/uploads/GBTA22_blog_feature-1-720x415.jpg",
        date : 'August 18, 2022',
        title : "5 ways Corporate Travel Managers can make progress on NDC",
        urlink: "https://www.sabre.com/insights/delivering-software-faster%e2%80%afhow-to-coax-git-into-working-great-with-a-monorepo/",
        subtl : "NDC affects the entire travel value chain. Airlines are credited for creating the concept, but travel agencies, online booking tools and corporate travel buyers want to understand more clearly “what’s in it for them” and what..."
    },
    {
        url : "/assets/b2.jpg",
        date : 'August 28, 2022',
        title : "Connecting with industry and academic peers on innovations in travel retailing",
        urlink: "https://www.sabre.com/insights/video-ndc-roadmap-highlights-with-kathy-morgan/",
        subtl : "The impact of the coronavirus pandemic on travel and the accelerated pace of technology evolution were core themes at Northwestern University Transportation Center’s annual workshop for travel industry and academic professionals. Sergey Shebalov, Vice President of… "
    },
]

export default BlogFile