import React from 'react'
import '../Blogcard/Blogcard.css'

const InnovationSmCard = ({slideImageurl, subtitle, dates, title, buttonclicked, butnlabel, linkurl}) => {

    const registeredClick = () =>{
        buttonclicked(true)
    }

  return (
    <div className='innovcard-container-blog' onClick={registeredClick}>
        <div className='imgdiv-container-contain'>
            <div className='imgdiv-container' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + slideImageurl})`}}/>
        </div>
        <div className='pc-card-body-blog'>
            <span id='date-tag'>{dates}</span>
            <div className='bg-text-container_'>
                <span id='bg-title'>{title}</span>
            </div>
            <div className='bg-text-container'>
                <div className='bg-text-wrapper'>
                    <span id='bg-name'>{subtitle}</span>
                </div>
            </div>
            <div className='readmore-container'>
                <a href={linkurl} rel="noreferrer" className='clickbutton'>
                    <button id='bg-link-btn'>{butnlabel}</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default InnovationSmCard