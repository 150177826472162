import React, {useState, useEffect} from 'react'
import './resources.css'
import { Link } from "react-router-dom";
import {BsChevronDoubleRight} from "react-icons/bs";
import ResourceCard from '../../Components/ResourceCard/ResourceCard';
// import ResourceJson from '../../Components/ResourceCard/ResourcesJson';
import axios from 'axios'
import ButtonPagenate from '../../Components/ButtonPagenate/ButtonPagenate';

const Videos = () => {
    const [swipe, setSwipe] = useState(true)
    const [incomingdocs, setIncomingdocs] = useState([])
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleformSubmit()
    }, [])

    const handleformSubmit = async () =>{
        try{
            var config = {
                method: 'GET',
                url: 'https://api.itbook.store/1.0/new',
            };

            await axios(config).then((response) => {
                setIncomingdocs(response.data.books)
            })
        } catch (e) {
            // console.log(e)
        }
    }

    const itemsPerpage = 8; //Number of items to be displayed per page 
    const pageCount = Math.ceil(incomingdocs.length / itemsPerpage);
    const pagesVisited = pageNumber * itemsPerpage;

    const handleSabrelab = () =>{

    }

    const handleSabreDev = () =>{
        
    }

    const handleSabrevid = () =>{
        
    }

    const handleRedapp = () =>{
        
    }

    const handleswipe = () =>{
        setSwipe(!swipe)
    }

    const handleresourceClick = () =>{

    }

    const newPagenum = (num) =>{
        setPageNumber(num)
    }

  return (
    <div className='resources-container'>
        <div className='blogpagee-container-bottm'>
            <div className={swipe ? 'exec-left-div-blogpage' : 'exec-left-div-blogpage-swipe'}>
                <div className='exec-left-blog-category'>
                    <div className='exec-left-blog-subcategory' onClick={handleSabrelab}>
                        <span>SABRE LABS & RESEARCH</span>
                    </div>

                    <div className='exec-left-blog-subcategory' onClick={handleSabreDev}>
                        <span>SABRE DEV STUDIO</span>
                    </div>

                    <Link to="/response/resource" className='exec-left-blog-subcategory'>
                        <span>SABRE MANUALS</span>
                    </Link>

                    <Link to="/response/videos" className='exec-left-blog-subcategory'>
                        <span>SABRE VIDEOS</span>
                    </Link>

                    <div className='exec-left-blog-subcategory' onClick={handleRedapp}>
                        <span>RED APP CENTER</span>
                    </div>
                </div>
            </div>

            <div className='exec-right-div-blpage'>
                <div className='res-team-head'><span>Sabre Resources</span></div>
                <div className='res-team-body-container'>
                {incomingdocs.slice(pagesVisited, pagesVisited + itemsPerpage).map((items) => 
                    <ResourceCard pictureclick={e => handleresourceClick(e, items.id)} title={items.title} imgurl={items.image} isbn={items.isbn13}/>
                )}
                </div>

                <ButtonPagenate 
                    updatedPageNum={newPagenum}
                    pageCount={pageCount}
                    pageNumber={pageNumber}
                    previousLabel="Prev"
                    nextLabel="Next"
                />
            </div>

            <div className='chevron-right-exec-cont' onClick={handleswipe}><BsChevronDoubleRight id='chevron-right-exec'/></div>
        </div>
    </div>
  )
}

export default Videos