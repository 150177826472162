const ExextempixJson = [
    {
        id: 1,
        imgurl: "https://portal.sabreng.com/files/executiveTeam/1452593517_1452593517_m.jpg",
        exename: "Dr. Gabriel O. Olowo",
        exetitle: "FNIM. FITP",
        exepost: "Chairman/CEO Interguide Group",
        exeabout: "Dr. Gabriel O. OLOWO is an Economist and Business Administrator with uninterrupted cognate experience in Aviation  dating back to 1973. \n\n He has attended several professional training courses and served on several expert groups, committees, and panels including the Ministerial Committee on Restructuring of Nigeria Airways Limited in September 2001, Ministerial Technical Committee on the Establishment of a new Flag Carrier in August 2003, Chairman of the Agency Investigation Panel (A.I.P) for Western Africa, and Member of Ecowas Parliament Committee on Implementation of Yammousoukro Treaty. \n\n Gabriel started his aviation career as a Management Trainee with Lufthansa German Airlines in 1973. He worked consistently in the aviation industry since then, serving at various capacities such as Assistant Manager with Lufthansa German Airlines, Deputy General Manager with Varig Brazilian Airline. \n\n Executive Director with Bellview Airlines Limited ( A leading private airline in Nigeria) and Country Director of Sabre Network Inc West Africa..... a US leading Technology company for Airlines. \n\n Gabriel’s deep and wide aviation industry experience has made him an acknowledged expert in airlines and aviation business management. He has provided varied consultancy services in these areas and is an author of many aviation industry related publications. He’s currently the  President Aviation Safety Round Table Initiative. \n\n Gabriel is a Fellow of the Nigerian Institute of Management (FNIM) ,  Member Nigeria/ American Chamber of Commerce, Nigeria-Brazil Chamber of Commerce & Industry and Nigeria/ British Chamber of Commerce.\n\n Gabriel had B. Sc. (Business Administration) Second Class Upper Honours, University of Lagos, Nigeria and M. Sc. Economics (Manpower Planning) University of Lagos, PhD ( Hons ) Business Administration in Samuel Adegboyega University, Edo, Nigeria."
    },
    {
        id: 2,
        imgurl: "",
        exename: "Joshua Olowo",
        exetitle: "",
        exepost: "President - Sabre CWA",
        exeabout: ""
    },
    {
        id: 3,
        imgurl: "https://portal.sabreng.com/files/executiveTeam/1452593856_1452593856_pp.jpg",
        exename: "Allen O. Awosikunde",
        exetitle: "",
        exepost: "Senior Vice President - Sabre CWA",
        exeabout: " Mr. Awosikunde holds a B.sc degree in Electrical/Electronic Engineering and M.Tech degree with specialization in Information Technology (IT). He has added various airline certificates in automated reservation system.\n\n He was appointed as the General Manager, Technical in November 2006. Prior to his career in Sabre, he has  worked as Information Technology (IT) Engineer with QED Nigeria, and Head of   Information Technology (IT) with Bellview Airlines Ltd for six (6) years."
    },
    {
        id: 4,
        imgurl: "https://portal.sabreng.com/files/executiveTeam/1452594483_1452594483_d.jpg",
        exename: "Emannuel O. Ishola",
        exetitle: "",
        exepost: "Vice President I - Sabre CWA",
        exeabout: "Mr. Isola holds a B.sc degree in Economics and MBA degree with specialization in Human Resources Management, a post graduate diploma in Desktop Publishing and Data processing. An Associate member of Nigeria Institute of Management (AMNIM) and a pioneer member of Kwara State NYSC Tourism Club. He has added various Airline certificates in automated reservation system.\n\n Prior to his career in Sabre, Mr Isola worked as a Management Trainee with Uda Community Bank and as a Personel Banker at First Bank of Nigeria in 2000-2001.\n\n He joined Interguide Air Ltd. in 2001 and was appointed Head of Agency in 2002, with the responsibility for worldwide Sales and Marketing, Network development, Revenue Management, Service development and overall management supervision of the agency."
    },
    {
        id: 5,
        imgurl: "",
        exename: "Hannah S. Ogunsulire",
        exetitle: "",
        exepost: "Vice President II - Sabre CWA",
        exeabout: "Mrs. Ogunsulire started her Aviation Career in 2003 as a Client Service Executive in Interguide Air Services. She was charged with the responsibility for group marketing, corporate clients, marketing strategy, marketing special projects, clients' security, marketing planning, inter-agency relationship and alliances.\n\n Hannah holds a Bachelor of Science degree in Regional Planning and a professional Master in Geographic Information System with various Airline certificate in Automated reservation system. Mrs. Ogunsulire has previously served in the Ministry of Environment as Management Trainee. She has acquired wide knowledge in the aviation industry that earned her the present position with Sabre as the Head, Research and Manpower Development. She is knowledgeable in Customer Service and had the capability of starting the Helpdesk of Sabre NG in 2006.\n\n She is an active member of the SKAL Association and many other Industry associations."
    },
    {
        id: 6,
        imgurl: "",
        exename: "Damilare O. Olayiwola",
        exetitle: "",
        exepost: "Regional Director - Sabre CWA",
        exeabout: ""
    },
]

export default ExextempixJson