import React from 'react'
import './ResourceCard.css'
import {TbArrowBigDownLinesFilled} from 'react-icons/tb'

const ResourceCard = ({title, imgurl, booksource, exepost}) => {
  return (
    <div className='resourcecard-container'>
        <div className='book-image' style={{ backgroundImage: `url(${imgurl})`}}/>
        <span>{title}</span>
        <div className='download-butn-container'>
          <TbArrowBigDownLinesFilled id='resource-download-icon'/>
          <button id='download-butn'>Download</button>
        </div>
    </div>
  )
}

export default ResourceCard