import React from 'react'
import Blogcard from '../Components/Cards/Blogcard/Blogcard'
import Card from '../Components/Cards/Card'
import './pages.css'
import BlogFile from '../Components/Cards/Blogcard/BlogJson'
import Smallercards from '../Components/Cards/Smallercards/Smallercards'
import SmallercardsJson from '../Components/Cards/Smallercards/Smallercardsjson'
import CardIndustry from '../Components/Cards/CardIndustry'
import CardInnovation from '../Components/Cards/Innovation/CardInnovation'
import InnovationSmCard from '../Components/Cards/Innovation/Innovationsmallercard'
import InnovationJson from '../Components/Cards/Innovation/innovationjson'
import Mainbloghead from '../Components/Cards/Blogcard/mainbloghead'
import Joinusnow from '../Components/Cards/Joinus/Joinus'
import JoinusJson from '../Components/Cards/Joinus/Joinusjson'
import BigCarousel from '../Components/Cards/Carousel/Carousel'

const Landingpage = () => {
  return (
    <div className='cwa-lndpg-mn-container'>
        <BigCarousel />
        <Card />
        <div className='industry-container'>
            <CardIndustry />
            <div className='small-cards-mncontainer'>
                {SmallercardsJson.map((items) => (
                    <Smallercards slideImageurl={items.imgurl} textslider={items.textname} linkurl={items.urlink}/>
                ))}
            </div>
        </div>

        <div className='innovation-container'>
            <CardInnovation />
            <div className='innoovation-card-container'>
            {InnovationJson.map((item) => (
                <InnovationSmCard slideImageurl={item.url} dates={item.date} title={item.title} subtitle={item.subtl} butnlabel={item.btnlabel} linkurl={item.urlink}/>
            ))}
            </div>
        </div>

        <div className='blog-container'>
            <Mainbloghead />
            <span>Insight Blogs</span>
            <div className='blogCards-container'>
                {BlogFile.map((item) => (
                    <Blogcard slideImageurl={item.url} dates={item.date} title={item.title} subtitle={item.subtl} linkurl={item.urlink}/>
                ))}
            </div>
            <button id='bg-link-btn'>VIEW MORE BLOGS</button>
        </div>

        <div className='JoinUs-container'>
            <Joinusnow />
            <div className='innoovation-card-container'>
            {JoinusJson.map((item) => (
                <InnovationSmCard slideImageurl={item.url} dates={item.date} title={item.title} subtitle={item.subtl} butnlabel={item.btnlabel}/>
            ))}
            </div>
        </div>
    </div>
  )
}

export default Landingpage